<template>
  <v-card>
    <v-card-title class="text-h5 primary--text">
      {{ isNew ? "Novo Imóvel" : "Editar Imóvel" }}

      <v-spacer />

      <label for="highlighted" class="text-body-2 mr-2">{{
        property.highlighted ? "Destacado" : "Em Destaque"
      }}</label>
      <v-switch id="highlighted" v-model="property.highlighted" />
    </v-card-title>

    <v-card-text>
      <form class="py-5">
        <v-row>
          <v-col cols="8">
            <v-combobox
              v-model="property.property_category_id"
              :filter="filter"
              :hide-no-data="!category_search"
              :items="categories"
              item-text="property_category"
              :search-input.sync="category_search"
              label="Categorias"
              @keydown.enter="addCategory"
              outlined
              dense
            >
              <template v-slot:no-data>
                <v-list-item link @click="addCategory">
                  <small class="mr-5">Adicionar</small>
                  <span class="primary--text">{{ category_search }}</span>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="property.property_type_id"
              :filter="filter"
              :hide-no-data="!type_search"
              :items="types"
              item-text="property_type"
              :search-input.sync="type_search"
              label="Tipo"
              @keydown.enter="addType"
              outlined
              dense
            >
              <template v-slot:no-data>
                <v-list-item link @click="addType">
                  <small class="mr-5">Adicionar</small>
                  <span class="primary--text">{{ type_search }}</span>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              v-model="property.cep"
              color="primary"
              label="CEP"
              v-mask="['#####-###']"
              outlined
              dense
            />
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="property.address"
              color="primary"
              label="Endereço"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="property.district"
              color="primary"
              label="Bairro"
              outlined
              dense
            />
          </v-col>
          <v-col cols="2">
            <v-text-field
              v-model="property.number"
              color="primary"
              label="Número"
              outlined
              dense
            />
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="property.city_id"
              :filter="filter"
              :hide-no-data="!city_search"
              :items="cities"
              item-text="city"
              :search-input.sync="city_search"
              label="Cidade"
              @keydown.enter="addCity"
              outlined
              dense
            >
              <template v-slot:no-data>
                <v-list-item link @click="addCity">
                  <small class="mr-5">Adicionar</small>
                  <span class="primary--text">{{ city_search }}</span>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-text-field
              v-model="property.complement"
              color="primary"
              label="Complemento"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-select
              :items="owners"
              item-value="id"
              item-text="name"
              v-model="property.owner_id"
              color="primary"
              label="Proprietário"
              no-data-text="Nenhum dado encontrado"
              outlined
              dense
            />
          </v-col>
          <v-col cols="6">
            <v-select
              :items="brokers"
              item-value="id"
              item-text="name"
              v-model="property.broker_id"
              label="Corretor"
              color="primary"
              no-data-text="Nenhum dado encontrado"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="8">
            <v-text-field
              v-model="property.title"
              label="Título"
              color="primary"
              outlined
              dense
            />
          </v-col>
          <v-col cols="4">
            <v-text-field-money
              color="primary"
              v-model="property.value"
              label="Valor"
              :properties="{
                prefix: 'R$',
                outlined: true,
                clearable: true,
                placeholder: ' ',
              }"
              :options="{
                humanMask: '###.###.##0,00',
                machineMask: '########0.00',
                empty: null,
              }"
              :focus="focus"
              v-on:focus="focus = false"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <label class="text-subtitle-2">Breve Descrição</label>
            <VueEditor
              v-model="property.short_description"
              :editorToolbar="customToolbar"
              class="background"
            />
          </v-col>
          <v-col cols="12">
            <label class="text-subtitle-2">Descrição Detalhada do imóvel</label>
            <VueEditor
              v-model="property.description"
              :editorToolbar="customToolbar"
              class="background"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-text-field
              prepend-inner-icon="mdi-bed"
              type="number"
              label="Quartos"
              v-model="property.bedroom"
              min="0"
              outlined
              dense
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              prepend-inner-icon="mdi-shower-head"
              type="number"
              label="Banheiros"
              v-model="property.bathroom"
              min="0"
              outlined
              dense
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              prepend-inner-icon="mdi-car"
              type="number"
              label="Garagem"
              v-model="property.garage"
              min="0"
              outlined
              dense
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <v-combobox
              v-model="property.property_tag_id"
              :filter="filter"
              :hide-no-data="!tag_search"
              :items="tags"
              item-text="property_tag"
              :search-input.sync="tag_search"
              label="Tags"
              @keydown.enter="addTag"
              outlined
              dense
            >
              <template v-slot:no-data>
                <v-list-item link @click="addTag">
                  <small class="mr-5">Adicionar</small>
                  <span class="primary--text">{{ tag_search }}</span>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-combobox
              v-model="property.form_payment_id"
              :filter="filter"
              :hide-no-data="!payment_search"
              :items="payments"
              item-text="form_payment"
              :search-input.sync="payment_search"
              label="Formas de pagamento"
              @keydown.enter="addPayment"
              outlined
              dense
            >
              <template v-slot:no-data>
                <v-list-item link @click="addPayment">
                  <small class="mr-5">Adicionar</small>
                  <span class="primary--text">{{ payment_search }}</span>
                </v-list-item>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="property.property_status_id"
              :items="status"
              item-value="id"
              item-text="property_status"
              color="primary"
              label="Status"
              no-data-text="Nenhum dado encontrado"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </form>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn color="primary" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn v-if="isNew" color="primary" @click="create">
        <v-icon small>mdi-check</v-icon>
        Cadastrar
      </v-btn>
      <v-btn v-else color="primary" @click="update">
        <v-icon small>mdi-check</v-icon>
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { VueEditor } from "vue2-editor";
import Money from "@/components/masks/Money.vue";
export default {
  props: {
    isNew: Boolean,
    property: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueEditor,
    "v-text-field-money": Money,
  },
  data: () => ({
    customToolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
    showPass: false,
    profiles: [],
    categories: [],
    types: [],
    cities: [],
    brokers: [],
    tags: [],
    payments: [],
    owners: [],
    status: [],

    type_search: null,
    category_search: null,
    city_search: null,
    payment_search: null,
    tag_search: null,
  }),
  methods: {
    filter(item, queryText, itemText) {
      if (item.header) return false;

      const hasValue = (val) => (val != null ? val : "");

      const text = hasValue(itemText);
      const query = hasValue(queryText);

      return (
        text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) >
        -1
      );
    },
    addCategory() {
      this.$api
        .post("property_categories", {
          property_category: this.category_search,
        })
        .then(() => {
          this.getCategories();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addType() {
      this.$api
        .post("property_types", { property_type: this.type_search })
        .then(() => {
          this.getTypes();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addCity() {
      this.$api
        .post("cities", { city: this.city_search })
        .then(() => {
          this.getCities();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addPayment() {
      this.$api
        .post("form_payments", { form_payment: this.payment_search })
        .then(() => {
          this.getPayments();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addTag() {
      this.$api
        .post("property_tags", { property_tag: this.tag_search })
        .then(() => {
          this.getTags();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCategories() {
      this.$api.get("property_categories").then((res) => {
        this.categories = res.data.data;
      });
    },
    getTypes() {
      this.$api.get("property_types").then((res) => {
        this.types = res.data.data;
      });
    },
    getCities() {
      this.$api.get("cities").then((res) => {
        this.cities = res.data.data;
      });
    },
    getOwners() {
      this.$api.get("people/profile/5").then((res) => {
        this.owners = res.data.data;
      });
    },
    getBrokers() {
      this.$api.get("people/profile/3").then((res) => {
        this.brokers = res.data.data;
      });
    },
    getTags() {
      this.$api.get("property_tags").then((res) => {
        this.tags = res.data.data;
      });
    },
    getPayments() {
      this.$api.get("form_payments").then((res) => {
        this.payments = res.data.data;
      });
    },
    getStatus() {
      this.$api.get("property_status").then((res) => {
        this.status = res.data.data;
      });
    },
    create() {
      this.$emit("create");
      this.$emit("close");
    },
    update() {
      this.$emit("update");
      this.$emit("close");
    },
  },
  mounted() {
    this.getPayments();
    this.getTags();
    this.getBrokers();
    this.getOwners();
    this.getCities();
    this.getTypes();
    this.getCategories();
    this.getStatus();
  },
};
</script>